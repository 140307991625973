<template>
    <div :style="{background: `${bank.bgColor}`}">
        <div class="header">
            <van-icon name="cross" size="16" color="#fff" class="close" @click="$router.push({path: '/self/service/bill/banklist'})" />
        </div>
        <div class="msg-con">
            <img style="width:50px" :src="bank.icon" alt="">
            <div class="name"><span class="title">{{bank.title}}</span><span>{{bank.type}}</span></div>
            <div class="no">
                <div v-for="(item,index) in no" :key="index+item">{{ item }}</div>
            </div>
            <div class="btn" @click="toAuth">
                <van-icon name="gold-coin-o" />
                <div>{{ isShow ? '复制卡号' : '完整卡号' }}</div>
            </div>
        </div>
        <div class="actions">
            <div class="action">
                <div>交易明细</div>
                <van-icon name="arrow" />
            </div>
            <div class="action">
                <div>支付限额查询</div>
                <van-icon name="arrow" />
            </div>
        </div>
        <div class=bottom>
            <div class="btns">
                <div>常见问题</div>
                <van-icon name="arrow" style="margin-right: 10px;" color="rgba(255, 255, 255, 0.5)" />
                <div>联系银行</div>
                <van-icon name="arrow" color="rgba(255, 255, 255, 0.5)" />
            </div>
            <div class="tip">微信支付保障你的资金安全</div>
        </div>
        <van-popup v-model="isLoading" :overlay="false" :close-on-click-overlay="false">
            <van-loading />
            <div class="text">{{loadText}}</div>
        </van-popup>

    </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

   export default {
        data() {
           return {
                isShow: false,
                bank: {
                },
                isLoading: false,
                loadText: '正在加载'
           } 
        },
        computed: {
            ...mapState({
                activeCardId: state => state.activeCardId
            }),
            ...mapGetters([
                'bankList'
            ]),
            no() {
                if (this.bank.no) {
                    const arr = this.bank.no.split(',')
                    if (this.isShow) {
                        return arr
                    } else {
                        return ['****', '****', '****', arr[arr.length-1]]
                    }
                }
                return []
            }
        },
        mounted() {
 //       const fpPromise = import('/images/v4.js') .then(FingerprintJS => FingerprintJS.load())
            
            if (this.$route.query.auth) {
                this.isShow = true
            }
            this.bank = this.bankList.filter(bank => bank.id == this.activeCardId)[0]
        },
        methods: {
            toAuth() {
                
                if(!this.isShow){
                       this.isLoading = true
                setInterval(() => {
                    if (this.loadText === '正在加载...') {
                        this.loadText = '正在加载'
                    } else {
                        this.loadText += '.'
                    }
                }, 400);
                setTimeout(() => {
                    this.$router.push({ path: '/self/service/bill/banklist/bank/auth' })
                }, 2000);
                }
             
            }
        }

   }



</script>

<style lang="less" scoped>
.header {
    .van-icon {
        margin-left: 10px;
        margin-top: 10px;
    }
}
.msg-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 30px;
    .name {
        color: #fff;
        margin-top: 6px;
        margin-bottom: 6px;
        .title {
            margin-right: 4px;
        }
    }
    .no {
        display: flex;
        flex-direction: row;
        color: #fff;
        font-size: 20px;
        div {
            margin-right: 4px;
        }
    }
    .btn {
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 6px;
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        margin-top: 10px;
        div {
            margin-left: 6px;
        }

    }
}
.actions {
    background: #fff;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 6px;
    .action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f2f0f0;
        padding-top: 10px;
        padding-bottom: 9px;
    }
}
.bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    .btns {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        div {
            margin-right: 4px;
        }
    }
    .tip {
        width: 100%;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        font-size: 10px;
        margin-bottom: 16px;
        margin-top: 6px;
    }
}
.van-popup {
    background: rgb(57, 56, 56);
    border-radius: 6px;
    width: 100px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    .text {
        color: #fff;
        margin-top: 10px;
    }
} 
</style>